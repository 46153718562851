<template>
	<div class="goods">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="合作商品" name="first"></el-tab-pane>
			<el-tab-pane label="共享商品" name="second"></el-tab-pane>
		</el-tabs>
		<el-form :inline="true" ref="form" :model="form" label-width="auto" size="mini">
			<el-form-item label="">
				<el-input v-model="form.query" placeholder="请输入商品名"></el-input>
			</el-form-item>
			<el-form-item label="">
				<el-input v-model="form.shop_name" placeholder="请输入店铺名"></el-input>
			</el-form-item>
			<el-form-item label="">
				<el-select v-model="form.cat" placeholder="商品分类">
					<el-option :label="cate.name" :value="cate.id"  v-for="cate in category" :key="cate.index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="">
				<el-select v-model="form.rate" placeholder="星级">
				<el-option label="一星" value="1"></el-option>
				<el-option label="二星" value="2"></el-option>
					<el-option label="三星" value="3"></el-option>
					<el-option label="四星" value="4"></el-option>
					<el-option label="五星" value="5"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="">
				<el-select v-model="form.web" placeholder="店铺平台">
				<el-option label="淘宝" value="0"></el-option>
				<el-option label="天猫" value="1"></el-option>
				</el-select>
			</el-form-item>
<!--			<el-form-item label="">-->
<!--				<el-select v-model="form.region" placeholder="招商人">-->
<!--				<el-option label="区域一" value="shanghai"></el-option>-->
<!--				<el-option label="区域二" value="beijing"></el-option>-->
<!--				</el-select>-->
<!--			</el-form-item>-->
<!--			<el-form-item label="">-->
<!--				<el-select v-model="form.region" placeholder="接待人">-->
<!--				<el-option label="区域一" value="shanghai"></el-option>-->
<!--				<el-option label="区域二" value="beijing"></el-option>-->
<!--				</el-select>-->
<!--			</el-form-item>-->
			<el-form-item label="直播价">
				<el-col :span="11">
					<el-input v-model="form.zb_price" class="w140"></el-input>
				</el-col>
				<el-col class="pl4 pr4" :span="1">~</el-col>
				<el-col :span="11">
					<el-input v-model="form.zb_e_price" class="w140"></el-input>
				</el-col>
			</el-form-item>
			<el-form-item label="销售额">
				<el-col :span="11">
					<el-input v-model="form.s_xse" class="w140"></el-input>
				</el-col>
				<el-col class="pl4 pr4" :span="1">~</el-col>
				<el-col :span="11">
					<el-input v-model="form.e_xse" class="w140"></el-input>
				</el-col>
			</el-form-item>
			<el-form-item label="销量">
				<el-col :span="11">
					<el-input v-model="form.s_volume" class="w140"></el-input>
				</el-col>
				<el-col class="pl4 pr4" :span="1">~</el-col>
				<el-col :span="11">
					<el-input v-model="form.e_volume" class="w140"></el-input>
				</el-col>
			</el-form-item>
			<el-form-item label="直播次数">
				<el-col :span="11">
					<el-input v-model="form.s_zbtimes" class="w140"></el-input>
				</el-col>
				<el-col class="pl4 pr4" :span="1">~</el-col>
				<el-col :span="11">
					<el-input v-model="form.e_zbtimes" class="w140"></el-input>
				</el-col>
			</el-form-item>
			<el-form-item label="报名日期">
				<el-date-picker
					v-model="form.baotime"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
					</el-date-picker>
			</el-form-item>
			<el-form-item label="佣金比例">
				<el-col :span="11">
					<el-input v-model="form.s_rate" class="w140"> <template slot="append">%</template></el-input>
				</el-col>
				<el-col class="pl4 pr4" :span="1">~</el-col>
				<el-col :span="11">
					<el-input v-model="form.e_rate" class="w140"> <template slot="append">%</template></el-input>
				</el-col>
			</el-form-item>
			<el-form-item label="服务费">
				<el-col :span="11">
					<el-input v-model="form.s_fee" class="w140"></el-input>
				</el-col>
				<el-col class="pl4 pr4" :span="1">~</el-col>
				<el-col :span="11">
					<el-input v-model="form.e_fee" class="w140"></el-input>
				</el-col>
			</el-form-item>
			<el-form-item>
				<el-button @click="resetSearch()">重置</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="searchGoods()">查询</el-button>
			</el-form-item>
		</el-form>
		<div class="table_title flex_r bg_table">
			<span>商品信息</span>
			<span>价格</span>
			<span>直播数据</span>
			<span>销售数据</span>
			<span>商家信息</span>
			<span>备注</span>
			<span>操作</span>
		</div>
		<div  v-for="item in goodsLists" v-bind:key="item">
			<div class="item_title flex_r f_ai_c f_jc_b tc bg_table mt10 pl20 pr20 fs12">
				<span>最新活动时间：</span>
				<div>
					<img width="16" height="16" src="//img.alicdn.com/imgextra//cb/34/TB1nMIPiqL7gK0jSZFBwu0ZZpXa.png" alt="">
					<span>{{ item.nick }}</span>
				</div>
				<span>来源：{{ item.username }}</span>
			</div>
			<ul class="item_ul flex_r f_ai_c f_jc_b fs12">
				<li>
					<div class="flex_r f_ai_c">
						<img height="80" width="80" :src="item.pict_url" alt="">
						<div class="pl10">
							<div class="ellipsis_2 fs14 fw_b">{{ item.title }}</div>
							<el-rate class="mt10 mb10" v-model="item.rank" disabled></el-rate>
							<el-tag>{{ item.nick }}</el-tag>
						</div>
					</div>
				</li>
				<li>
					<div class="pb20">
						<span class="cr_999">直播价：</span>
						<span>¥{{ item.zb_price }}~{{ item.zb_e_price }}</span>
					</div>
					<div>
						<span class="cr_999">在售价：</span>
						<span>¥{{ item.s_price }}~{{ item.e_price }}</span>
					</div>
				</li>
				<li>
					<div>
						<span class="cr_999">直播：</span>
						<span>{{ item.zbTimes }}</span>
					</div>
				</li>
				<li>
					<div class="pb20">
						<span class="cr_999">总销量：</span>
						<span>{{ item.cjs }}</span>
					</div>
					<div>
						<span class="cr_999">总销售额：</span>
						<span>{{ item.xse }}</span>
					</div>
				</li>
				<li>
					<div>
						<span class="el-icon-user-solid"></span>
						<span>{{ item.username }}</span>
					</div>
					<div class="pt8 pb8">
						<span class="el-icon-phone"></span>
						<span>{{ item.mobile }}</span>
					</div>
					<div>
						<span class="el-icon-chat-dot-round"></span>
						<span>{{ item.qq }}</span>
					</div>
				</li>
				<li>
					<div>
						<span>{{ item.memo }}</span>
					</div>
				</li>
				<li>
					<div class="cur_p mb10"  @click="pj(item)" v-on:handleOpen="openDetails">商品评级</div>
					<router-link tag="a" target="" :to="{path:'/commodityDetails',query: {id:item.cgid}}">查看详情</router-link>
<!--					<div class="cur_p mt10">邀请报名</div>-->
				</li>
			</ul>
		</div>
		<el-pagination
			class="tr"
			:small="true"
			@current-change="handleCurrentChange"
			:current-page.sync="currentPage"
			:page-size="pageSize"
			:total="total">
		</el-pagination>
		<e-dialog title="评级" ref="dialogRef" v-on:confirm="confirm">
			<div class="tc">
				<el-rate v-model="rate"></el-rate>
				<el-input type="textarea" v-model="memo" placeholder="备注"></el-input>
			</div>
		</e-dialog>
	</div>
</template>

<script>
import {businessTableConf, searchFormList} from '@data/business'
export default {
	name: 'Goods',
	data() {
		return {
			form: {},
			businessTableConf,
			searchFormList,
			dataOrigin: {
				data: []
			},
			activeName: "first",
			currentPage: 1,
			pageSize: 10,
			total: 0,
			goodsLists: [],
			category: [],
			rate: 0,
			memo: ''
		}
	},
	methods: {
		confirm(){
			let that = this
			this.post('?server=live.goods.rank.handle.post',{
				note:this.memo,
				rank:this.rate,
				id:this.id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$refs.dialogRef.handleClose()
				that.id = ''
				that.rate = 0
				that.memo = ''
				that.GetGoodsLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		pj(e){
			console.log(e)
			this.id = e.id
			// let formData = this.$refs.formRef.formData
			this.$refs.dialogRef.handleOpen()
		},
		openDetails(row){
			console.log(row)
		},
		resetSearch(){
			this.form = {}
			this.currentPage = 1
			this.GetGoodsLists()
		},
		searchGoods() {
			this.currentPage = 1
			this.GetGoodsLists()
		},
		handleClick() {
			this.form = {}
			this.currentPage = 1
			this.GetGoodsLists()
		},
		GetCategoryLists(){
			let that = this
			this.category = []
			this.get('?server=live.goods.category.lists.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.category = response.data
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetGoodsLists(){
			let that = this
			this.goodsLists = []
			this.get('?server=live.goods.lists.get',{type:this.activeName,
				page:this.currentPage,
				query:this.form.name,
				shop_name:this.form.shop_name,
				cat:this.form.cat,
				rate:this.form.rate,
				web:this.form.web,
				zb_price:this.form.zb_price,
				zb_e_price:this.form.zb_e_price,
				s_xse:this.form.s_xse,
				e_xse:this.form.e_xse,
				s_volume:this.form.s_volume,
				e_volume:this.form.e_volume,
				s_zbtimes:this.form.s_zbtimes,
				e_zbtimes:this.form.e_zbtimes,
				s_rate:this.form.s_rate,
				e_rate:this.form.e_rate,
				s_fee:this.form.s_fee,
				e_fee:this.form.e_fee,
				baotime:this.form.baotime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.goodsLists = response.data.data
				that.total = response.data.total
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		handleCurrentChange(val){
			this.currentPage = val
			this.GetGoodsLists()
		},
	},
	created() {
		this.GetGoodsLists()
		this.GetCategoryLists()
	}
}
</script>

<style lang="scss" scoped>
.w140 {
	width: 140px;	
}
.table_title {
	span {
		line-height: 40px;
		width: 14%;
		text-align: center;
		&:nth-child(1) {
			width: 20%;
		}
		&:nth-child(7) {
			width: 80px;
		}
	}
}
.item_title {
	line-height: 30px;
}
.item_ul {
	li {
		width: 14%;
		padding: 10px;
		text-align: center;
		&:nth-child(1) {
			width: 20%;
		}
		&:nth-child(7) {
			width: 80px;
		}
	}
}
</style>