export const businessTableConf = [
	{
		label: '商家信息',
		prop: 'account1',
		width: 240
	},
	{
		label: '商品',
		prop: 'goodsNum'
	},
	{
		label: '直播次数',
		prop: 'zbTimes'
	},
	{
		label: '销量',
		prop: 'cjs'
	},
	{
		label: '销售额',
		prop: 'xse'
	},
	{
		label: '备注信息',
		prop: 'note'
	},
	{
		label: '操作',
		prop: 'operation',
		width: 90
	}
]

export const searchFormList = [
	{
		label: '商家信息',
		prop: 'query',
		type: 'input'
	},
	{
		label: '商家评级',
		prop: 'rate',
		type: 'select',
		options: [
			{
				label: '全部',
				value: 0
			},
			{
				label: '一星',
				value: 1
			}
			,
			{
				label: '二星',
				value: 2
			}
			,
			{
				label: '三星',
				value: 3
			}
			,
			{
				label: '四星',
				value: 4
			},
			{
				label: '五星',
				value: 5
			}
		]
	},
]

export const detailPartnerTableConf = [
	{
		label: '商品信息',
		prop: 'account1',
		width: 200
	},
	{
		label: '直播次数',
		prop: 'zbTimes'
	},
	{
		label: '总销量',
		prop: 'cjs'
	},
	{
		label: '总销售额',
		prop: 'xse'
	},
	{
		label: '最低直播价',
		prop: 'zb_price'
	},
	{
		label: '最近直播',
		prop: 'account6'
	},
	{
		label: '操作',
		prop: 'operation',
		width: 90
	}
]

export const commodityDetailsTableConf = [
	{
		label: '开播时间',
		prop: 'zbtime',
		width: 200
	},
	{
		label: '主播信息',
		prop: 'username'
	},
	{
		label: '总销量',
		prop: 'cjs'
	},
	{
		label: '直播价',
		prop: 'zb_price'
	},
	{
		label: '销售额',
		prop: 'xse'
	},
	{
		label: '操作',
		prop: 'operation',
		width: 90
	}
]
